import {useEffect, useState} from 'react';
import {useTheme} from "../../../hook/useTheme";
import Skelet from "../../../elements/Skelet";
import {useGetGoalsUser} from "../../../hook/useGetGoals";


const GoalUser = () => {
    const {data: goaluser, isLoading, isError} = useGetGoalsUser(2024,12,'all')
    const dark = useTheme() // тема
    const [data, setData] = useState()

    useEffect(()=>{
        if (goaluser){
            let x = goaluser.data.response.data
            console.log(x)
            setData(x)
        }
    },[goaluser])

    if (isLoading) {return <Skelet option='ecoData'/>}
    if (isError) {return <h3>Нет подключения к серверу</h3>}
    if (!goaluser) {return <h3>Нет данных с сервера</h3>}

    return (
        <div>
            <div style={{textAlign: 'center'}}>Доступные данные по KPI</div>
            <div style={{display: 'flex', justifyContent: 'space-around', marginTop: '30px'}}>
                {
                    data && data.map((item,i) =>{
                        return (
                            <div key={i} style={{padding: '15px', textAlign: 'center', flexBasis: '170px', border: '1px solid #818181', borderRadius: '10px', marginBottom: '10px', cursor: 'pointer'}}>
                                <div>{item.name}</div>
                                <div>код: {item.id}</div>
                            </div>
                        )
                    })
                }

            </div>
            {/*<GoalMatrix data={data}/>*/}
        </div>
    );
};

export default GoalUser;