import './dev.scss'

const Dev = () => {
    return (
        <div className='pinkNeon'>
            Блок находится в разработке
        </div>
    );
};

export default Dev;