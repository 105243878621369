import '../../hr.scss'
import {GFormControl, GInputLabel, GTextField} from "../../../../elements/CustomMui/customMui";
import {useTheme} from "../../../../hook/useTheme";
import {MenuItem, Select, Stack, Typography} from "@mui/material";
import React, {useState} from "react";
import HomeIcon from "@mui/icons-material/Home";
import Diversity3Icon from '@mui/icons-material/Diversity3';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

const Podrazdelenie = () => {
    const [dep, setDep] = useState('company');

    const depList = [
        {
            val: 'ahch',
            name: 'АХЧ и секретариат',
        },
        {
            val: 'buh',
            name: 'Бухгалтерия',
        },
        {
            val: 'it',
            name: 'ИТ служба',
        },
        {
            val: 'hr',
            name: 'Отдел кадров',
        },
        {
            val: 'plan',
            name: 'Отдел планирования',
        },
        {
            val: 'pro',
            name: 'Производство',
        },
        {
            val: 'fin',
            name: 'Финансовый отдел',
        },
    ]


    const handleChangeHolding = (e)=>{
        setDep(e.target.value);

    }

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                    <DatePicker label="Basic date picker" label={'"month" and "year"'} views={['month', 'year']}/>
                </DemoContainer>
            </LocalizationProvider>
            <GFormControl sx={{width: 250,mr: '15px', borderBottom: '0.1rem solid #ffffff4a;'}} variant="standard" >
                <GInputLabel sx={{color: useTheme('text')}}>Подразделение</GInputLabel>
                <Select
                    labelId="dep-label"
                    id="dep"
                    value={dep}
                    defaultValue='company'
                    onChange={handleChangeHolding}
                    sx={{color: useTheme('text'),width: 250, height: 32, display: 'inline-flex'}}
                    inputProps={{
                        MenuProps: {
                            MenuListProps: {
                                sx: {
                                    backgroundColor: useTheme('select'),
                                    color: useTheme('text')
                                }
                            }
                        }
                    }}
                >
                    <MenuItem value={'company'}>
                        <Stack direction="row" alignItems="center" gap={1}>
                            <HomeIcon /><div>Гардиан</div>
                        </Stack>
                    </MenuItem>
                    {
                        depList.map((item, i)=>{
                            return (
                                <MenuItem key={i} value={item.val}>
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        {/*<Diversity3Icon/>*/}
                                        <div style={{paddingLeft: '30px'}}>{item.name}</div>
                                    </Stack>
                                </MenuItem>
                            )
                        })
                    }
                </Select>
            </GFormControl>
            <GFormControl sx={{width: 250,mr: '15px', borderBottom: '0.1rem solid #ffffff4a;'}}  >
                <GTextField  id="name" label="Период" type='text'  value={'01/2025'} variant="standard"/>
            </GFormControl>

        </div>
    );
};

export default Podrazdelenie;

