import axios from "axios";
import {BACK} from "../utils/links";
import {useQuery} from "react-query";

const link = `${BACK}/api/iboardData/getGoals`

const options = {
    keepPreviousData: true,
    /*refetchOnWindowFocus: true,*/
    enabled: true
}

export const useGetGoalsUser = (year = 0, month = 0,user = 'all') => {
    return useQuery(['goaluser',year, month, user],  async ()=> {
            const data = await axios.get(`${link}/${year}/${month}/${user}`)
            return data
        },
        options)
}
