import React from 'react';
import Dev from "../../../elements/Development/Dev";

const GoalObject = () => {
    return (
        <div>
            <Dev/>
        </div>
    );
};

export default GoalObject;